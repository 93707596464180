









































import { Component, Vue } from "vue-property-decorator";
import DashTotalTipoCitasModule from "@/store/modules/Dashboards/Empresarial/DashTotalTipoCitas-module";
import VueApexCharts from "vue-apexcharts";
import { DashColors } from "@/views/Dashboards/Dashcolors";
@Component({
  components: {
    VueApexCharts
  }
})
export default class ChartEvolucionAgenda extends Vue {
  public AnosSeleccionadosItems: number[] = [];
  public ejecutado = false;
  public created() {}

  public get Años() {
    let todosLosaños: any[] = [];
    let source =
      DashTotalTipoCitasModule.DashTotalTipoCitas.datos_agrupados_por_fecha;

    //hay que poner un try sino no te lo pinta
    try {
      for (let i = 0; i < source.length; i++) {
        todosLosaños.push(new Date(source[i].fecha).getFullYear());
      }
    } catch (error) {}

    for (let i = 0; i < todosLosaños.length; i++) {
      let encontrado = false;
      for (let j = 0; j < this.AnosSeleccionadosItems.length; j++) {
        if (this.AnosSeleccionadosItems[j] === todosLosaños[i]) {
          encontrado = true;
        }
      }
      if (!encontrado && !this.ejecutado) {
        this.AnosSeleccionadosItems.push(todosLosaños[i]);
      }
    }
    this.ejecutado = this.AnosSeleccionadosItems.length > 0;
    return todosLosaños;
  }
  public DataSerie(TipoCita: number) {
    let citafindlabel: string = "Cita pendiente";
    switch (TipoCita) {
      case 0:
        citafindlabel = "Cita pendiente";
        break;
      case 1:
        citafindlabel = "Cita realizada";
        break;
      case 2:
        citafindlabel = "Cita cancelada";
        break;
    }
    let dataSerie: number[] = [];
    let source =
      DashTotalTipoCitasModule.DashTotalTipoCitas.datos_agrupados_por_fecha;
    //hay que poner un try sino no te lo pinta
    try {
      for (let i = 0; i < source.length; i++) {
        let ano = new Date(source[i].fecha).getFullYear();

        let encontrado = false;
        for (let j = 0; j < this.AnosSeleccionadosItems.length; j++) {
          if (this.AnosSeleccionadosItems[j] === ano) {
            encontrado = true;
          }
        }
        if (
          encontrado &&
          citafindlabel.toLowerCase() === source[i].tipo.toLowerCase()
        ) {
          dataSerie.push(source[i].total);
        }
      }
    } catch (error) {}

    return dataSerie;
  }
  public get DataCategories() {
    let dataCategories: Date[] = [];
    let source =
      DashTotalTipoCitasModule.DashTotalTipoCitas.datos_agrupados_por_fecha;
    //hay que poner un try sino no te lo pinta
    try {
      for (let i = 0; i < source.length; i++) {
        let ano = new Date(source[i].fecha).getFullYear();
        let encontrado = false;
        for (let j = 0; j < this.AnosSeleccionadosItems.length; j++) {
          if (this.AnosSeleccionadosItems[j] === ano) {
            encontrado = true;
          }
        }
        if (encontrado) {
          encontrado = false;
          //Ahora miramos si esta fecha ya esta en el array
          for (let k = 0; k < dataCategories.length; k++) {
            if (source[i].fecha === dataCategories[k]) {
              encontrado = true;
            }
          }
          //Y al contrario de lo anteriror ahora si no esta en el array se pone
          if (!encontrado) {
            dataCategories.push(source[i].fecha);
          }
        }
      }
    } catch (error) {}
    return dataCategories;
  }

  public get lineAreaChartSpline() {
    return {
      series: [
        {
          name: "Cita pendiente",
          data: this.DataSerie(0)
        },
        {
          name: "Cita realizada",
          data: this.DataSerie(1)
        },
        {
          name: "Cita cancelada",
          data: this.DataSerie(2)
        }
      ],
      chartOptions: {
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        colors: DashColors.colors,
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        markers: {
          size: 5,
          hover: {
            size: 9
          }
        },
        xaxis: {
          type: "datetime",
          categories: this.DataCategories
        },
        tooltip: {
          x: {
            format: "dd/MM/yyyy"
          }
        }
      }
    };
  }
}
